<template>
  <div class="report">
      <div class="report__body">
        <div class="report__header">
          <h4>Экспорт заявок  <b-icon class="float_right" icon="x-circle" scale="0.8" @click="hideModal()"></b-icon></h4>
        </div>
        <b-container class="bv-example-row">
          <b-row>
            <b-col align-self="center">
               Диапазон
            </b-col>
            <b-col>  
                <b-form-group v-slot="{ ariaDescribedby }" class="m-auto" >
                  <b-form-radio v-model="selected" value="1" :aria-describedby="ariaDescribedby">За текущий месяц</b-form-radio>
                  <b-form-radio v-model="selected" value="2" :aria-describedby="ariaDescribedby">За прошлый месяц</b-form-radio>
                  <b-form-radio v-model="selected" value="3" :aria-describedby="ariaDescribedby">За период</b-form-radio>
               </b-form-group>          
            </b-col>
          </b-row>
        </b-container>
        <div class="filter__item">
            <b-container v-if="showPeriod" class="bv-example-row" >
            <b-row>
              <b-col align-self="center" cols="2">
                Укажите диапазон 
              </b-col>
              <b-col class="flex">
                <b-input-group class="my-auto m-2">
                  <b-form-input
                    id="example-input"
                    v-model="from"
                    type="text"
                    size="sm" 
                  ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="from"
                        button-only
                        size="sm" 
                        locale="ru"
                      ></b-form-datepicker>
                    </b-input-group-append>
                 </b-input-group>
                 <b-icon-dash class="mx-auto"></b-icon-dash>
                 <b-input-group class="my-auto m-2">
                  <b-form-input
                    id="example-input"
                    v-model="to"
                    type="text"
                    size="sm" 
                  ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="to"
                        button-only
                        size="sm" 
                        locale="ru"
                      ></b-form-datepicker>
                    </b-input-group-append>
                 </b-input-group>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <button  class="report__btn" @click="reportPrint()">Сформировать отчет</button>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
   export default {
      data() {
        return {
          selected: 1,
          range: [],
          showCalendar : false,
          showPeriod: false,
          from : new Date().toISOString().split('T')[0],
          to : new Date().toISOString().split('T')[0]
        }
      },
      watch: {
        selected: function(){
          if(this.selected == 3){
            this.showPeriod = !this.showPeriod
          }else if(this.selected != 3){
            this.showPeriod = false
          }
        }
      },

      methods: {
         reportPrint() {
          if(this.selected == 1){
                let from;
                let to;
                let date = new Date();
                let fromYear = date.getFullYear();
                let fromMonth = date.getMonth() + 1;
                let fromDay =  (date.getDate() - date.getDate()) + 1;

                let toMonth =  date.getMonth() + 2;

                if(fromMonth < 10) fromMonth = '0' + fromMonth
                if(toMonth < 10) toMonth = '0' + toMonth

                from = `${fromYear}-${fromMonth}-0${fromDay}`  
                to = `${fromYear}-${toMonth}-0${fromDay}`  
                this.exportToPdf(from, to)
                this.hideModal();
                
          }else if(this.selected == 2){
                let from;
                let to;
                let date = new Date();
                let fromYear = date.getFullYear();
                let fromMonth = date.getMonth();
                let fromDay =  (date.getDate() - date.getDate()) + 1;

                let toMonth =  date.getMonth() + 1;
                let toYear = date.getFullYear();

                if(fromMonth < 10){
                  fromMonth = '0' + fromMonth
                  if(fromMonth == '00'){
                    fromMonth = 12;                  
                    fromYear = fromYear - 1;
                  }
                }
                if(toMonth < 10) toMonth = '0' + toMonth;

                from = `${fromYear}-${fromMonth}-0${fromDay}`  
                to = `${toYear}-${toMonth}-0${fromDay}`  
                this.exportToPdf(from, to)
                this.hideModal();
          }else{
            this.exportToPdf(this.from, this.to)
            this.hideModal();
          }
          
        },
        async exportToPdf(from, to){     // распечатать справку по маршрутам водителей
                  await axios({
                    method: 'get',
                    url : process.env.VUE_APP_API_URL + `/web/export-driver-info?from=${from}&to=${to}`,
                    headers: {
                       'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
                    },
                    responseType: 'blob'
                  }).then(response => {
                    const blob = new Blob([response.data], {
                      type: 'application/vnd.ms-excel'
                    });
                    var url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `История марштуров_${from}-${to}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                  }).catch(function(error) {
                    console.log(error)
                    })
            },
        hideModal(){
              this.$emit('showCalendar');
        }
      },
   }
</script>

<style scoped> 
.report {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow-y: scroll;
  transform: translateY(0%);
}

.report__body {
  width: 40%;
  margin: auto;
  margin-top: 10%;
  padding: 10px;
  background: white;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.report__header {
  margin: 10px;
  font-weight: 500;
}
.report__period {
  margin: 10px;
  margin-top: 30px;
  border: 1px black solid;
}
.report__btn {
  margin: 10px;
  margin-top: 40px;
  padding: 10px;
  border-radius: 5px;
  background: green;
  color: white;
}
.float_right {
   float: right;
   cursor : pointer;
}

.filter__item {
  position: relative;
  font-size: 14px;
  margin-top: 10px;
}
</style>